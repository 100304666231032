<template>
  <v-scroll-x-reverse-transition hide-on-leave>
    <router-view></router-view>
  </v-scroll-x-reverse-transition>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store'
import router from '../../../routes'

export default {
  name: 'Verein',
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (this.user.data) {
      if (
        this.user.data.rolle != 'Trainer' &&
        this.user.data.rolle != 'Vorstand'
      ) {
        router.push('/')
      }
    } else {
      router.push('/')
    }
  },
  created() {
    if (this.user.data) {
      if (
        this.user.data.rolle != 'Trainer' &&
        this.user.data.rolle != 'Vorstand'
      ) {
        router.push('/')
      }
    } else {
      router.push('/')
    }
  },
}
</script>
